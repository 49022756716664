.asset-profiles-container {
  margin: 0;
  padding: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  margin-bottom: 40px;
  padding: 0 15px;
}

.asset-profiles-header {
  justify-content: center;
  font-size: 22px;
  /* margin-bottom: 20px; */
}

.asset-profiles-form {
  margin: 0;
  padding: 0;
}

.asset-profiles-form-header-row {
  margin: 0;
  margin-bottom: 10px;
  align-items: center;
}

.asset-profiles-form-saving-sections {
  margin: 0;
  align-items: center;
}

.asset-profiles-section {
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0;
  padding: 15px;
}

.asset-profiles-section-name {
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.asset-profiles-required-info-row {
  justify-content: flex-end;
  display: flex;
  font-weight: normal;
  font-style: italic;
}

.asset-profiles-section:not(:last-child) {
  margin-bottom: 15px;
}

.asset-profiles-social-dropdown {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.asset-profiles-link-text {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.asset-profiles-form-control-sub-text {
  font-size: 13px;
  font-style: italic;
}

.asset-profile-subfield {
  margin: 0;
  padding: 0;
}

.asset-profile-subfield:not(:last-child) {
  margin-bottom: 10px;
}

.asset-profile-image-uploader-row {
  margin: 0;
  justify-content: center;
}

.asset-profile-image-uploader .fileContainer {
}

.asset-profile-uploaded-image-container {
  position: relative;
  width: 200px;
  min-height: 173px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.asset-profile-uploaded-image {
  width: 200px;
  object-fit: cover;
  justify-content: center;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.asset-profile-remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 100px;
  color: cornflowerblue;
}

.asset-profile-remove-icon:hover {
  cursor: pointer;
  color: white;
  background: cornflowerblue;
}

.asset-profile-loading-container {
  height: 100%;
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}

.asset-profile-document-uploader-row {
  margin: 0;
  width: 100%;
}

.asset-profile-form-control::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-typeahead::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.asset-profile-document-uploader-row {
  align-items: center;
}

.asset-profile-document-name {
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
  align-items: center;
}

.asset-profile-document-clear-icon {
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}

.asset-profile-document-clear-icon:hover {
  background: white;
  color: black;
  cursor: pointer;
}

.asset-profile-datepicker .react-date-picker__wrapper {
  background: rgba(0, 0, 0, 0.1);
}

.asset-profile-datepicker .react-date-picker__inputGroup input {
  color: rgba(0, 0, 0, 0.7);
}

.asset-profile-create-new-modal {
  background: rgba(0, 0, 0, 0.4);
  margin-top: -93px;
}

.asset-profiles-asset-type {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 20px;
}

.asset-profiles-asset-type .form-control {
  color: black !important;
}

.asset-profile-create-asset-button {
  margin-top: 10px;
}

.asset-profiles-list {
  justify-content: center;
  margin-top: 20px;
}

.asset-profile-individual-profile-header {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  font-weight: bold;
  padding: 0 5px;
}

.asset-profile-individual-profile {
  margin: 0;
  padding: 0;
  align-items: center;
  padding: 5px;
}

.asset-profile-individual-profile .col#published {
  color: green;
}

.asset-profile-individual-profile .col#not-published {
  color: red;
}

.asset-profile-individual-profile:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.asset-profile-back-to-templates {
  margin-bottom: 10px;
}

.asset-profile-back-to-templates:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1586eb;
}

.asset-profiles-create-button-header {
  justify-content: center;
  margin-bottom: 10px;
}

.asset-profile-search-column {
  margin: 0;
  padding: 0;
}

.asset-profile-search-column:not(:last-child) {
  margin-right: 10px;
}

.asset-profile-search-column .form-control::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.common-image-th {
  width: 100px;
}

.common-table-image {
  max-width: 100px;
  max-height: 30px;
}

.individual-form-icon.up-button {
  margin: 0 !important;
}

.direction-button {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 100px;
  padding: 2px;
}

.direction-button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.direction-button-container {
  margin-left: 8px;
  width: 40px;
  display: flex;
  justify-content: flex-end;
}

.dropdown-checkbox {
  margin-left: 8px;
}

.link-row {
  width: 100%;
}

.disabled-datepicker {
  background-color: #e9ecef !important;
  opacity: 1 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.offering-document-container {
  width: 100%;
}

.offering-document-clear-button {
  margin-left: 16px;
  color: #858796;
}

.offering-document-clear-button:hover {
  color: black;
  cursor: pointer;
}
