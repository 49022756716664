.search-assets-form-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #858796 !important;
}

.search-assets-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.search-assets-table-row {
  align-items: center;
  width: max-content;
}

.search-assets-table-name {
  font-size: 12px;
}

.search-assets-table-cell {
  width: max-content;
  /* max-width: 200px; */
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.search-assets-table-cell-div {
  /* width: max-content; */
}

.search-assets-table-cell.description {
  font-size: 12px;
}

.search-assets-table th {

}

.search-assets-link {
}
