.fi-logo {
  width: 200px;
}

.middle-button-nav {
  width: 100%;
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
}

.navigation-bar {
  background: white;
  margin: 0;
  padding: 16px 0 16px 70px;
  height: 90px;
  width: 100%;
  justify-content: space-between;
  border-bottom:1px solid #232c56;
}

.navigation-bar-links-container {
  min-width: 50%;
  width: max-content !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.login-register-button-container {
  padding: 0;
  margin: 0;
}

.end-button-nav {
  width: 30%;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  font-size: 16px;
}

.navigation-bar-item {
  color: #232c56;
  margin: 0 5%;
}

.navigation-bar-item:hover {
  text-decoration: none !important;
  color: #232c56;
}

.navigation-bar-item:after {
  display:block;
  content: '';
  border-bottom: solid 1px #232c56;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-item:hover:after {
  transform: scaleX(1);
}

.navigation-bar-item {
}

.navigation-bar-dropdown {
  margin: 0;
  margin: 0 5%;
}

.navigation-bar-dropdown ul {
  margin: 0;
  padding: 0;
  padding: 10px;
}

.navigation-bar-dropdown a {
  color: #232c56 !important;
}

.navigation-bar-dropdown ul a:hover {
  text-decoration: none;
}

.navigation-bar-dropdown ul {
  color: #232c56 !important;
}

.navigation-bar-dropdown a:after {
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown a:hover:after {
  transform: scaleX(1);
}

.navigation-bar-dropdown ul:hover {
  cursor: pointer;
}

.navigation-bar-dropdown ul:after {
  display: block;
  content: '';
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown ul:hover:after {
  transform: scaleX(1);
}

.navigation-bar-dropdown .dropdown-menu {
  background: white;
  border-radius: 0px;
  border: solid 1px #232c56;
  font-size: 16px;
  margin-right: 20px !important;
}

.navigation-bar-dropdown .dropdown-item:hover {
  background: black;
}

.navigation-bar-dropdown .dropdown-item:after {
  display:block;
  content: '';
  border-bottom: solid 1px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navigation-bar-dropdown .dropdown-item:hover:after {
  transform: scaleX(1);
}

.user-topbar-nav {

}

.user-topbar-nav .dropdown-toggle {
  color: #232c56;
}

.user-topbar-nav .dropdown-toggle:hover {
  color: #232c56;
}

.user-topbar-nav a {
  color: black;
  align-items: center;
  display: flex;
}

.user-topbar-nav a:hover {
  color: black;
}

.user-topbar-nav .dropdown-item {
  justify-content: space-between;
}

.user-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.user-topbar-nav a::after {
  margin-left: 8px;
}

.topbar-icon {
  color: rgba(0, 0, 0, 0.3) !important;
}

.nav-bar-item {
  margin: 0 5%;
}

.user-topbar-item {
  font-size: 14px;
}

.user-topbar-text {
  font-size: 14px;
}

.user-topbar-user-icon {
  margin-left: 8px;
}
