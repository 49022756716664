.forgot-password-container {
  height: 100%;
  background-image: url('../../images/HomePage/header-background-image.png');
  background-color: #0d064d;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  align-items: center;
}

.forgot-password-box {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(2px);
  padding: 15px;
  color: white;
}

.forgot-password-form-control {
  border: none;
  border-bottom: 1px solid #008fff;
  border-radius: 0;
  background: transparent;
  color: white !important;
}

.forgot-password-form-control:focus {
  background: transparent;
}

.forgot-password-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.forgot-password-button-row {
  justify-content: center;
}

.forgot-password-button {
    width: 40%;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #1586eb;
    color: #45a7ff;
}

.forgot-password-button:hover {
  color: white;
  background: linear-gradient(to top, rgb(13, 6, 77, 0.8) 10%, rgb(42, 25, 205, 1) 100%);
  border: 1px solid #008fff;
}

.forgot-password-success-row {
  justify-content: center;
  text-align: center;
}
