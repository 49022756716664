/****************************** MISC STYLING **********************************/
.utdp-container {
    background: transparent;
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.utdp-loading-container {
    height: 100%;
    padding-top: 100px;
    max-width: 100%;
    min-width: 100%;
}
/****************************** END OF MISC STYLING ***************************/


/****************************** UTDP HEADER STYLING ***************************/
.utdp-header-container {
    /* width: 100% !important; */
    margin-bottom: 20px;
}

.utdp-header-row{
    margin-right:5px;
    margin-left:5px;
}
.utdp-header-column-a {
  background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
    padding: 15px;
}

.utdp-header-image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.utdp-token-image-container {
  justify-content: center;
  display: flex;
  align-items: center;
  /* width: 300px; */
}

.utdp-token-image {
  width: 100%;
  max-height: 100px;
  /* height: 90%; */
  object-fit: contain;
}

.utdp-header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.utdp-header-name h2 {
  color: #858796;
}

.utdp-header-industry {
}

.utdp-header-location {
    color: #858796;
    align-items: center;
}

.location-icon {
    margin-right: 5px;
}

.utdp-header-column-b {
    background: rgba(0, 0, 0, 0.1);
}

.utdp-header-button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    font-size: 14px;
}

.utdp-header-button {
    width: 180px;
    font-size: 14px;
    border-radius: 25px;
    margin-right: 40px;
}
/*********************** END OF UTDP HEADER STYLING ***************************/


/****************************** UTDP DETAILS STYLING **************************/
.utdp-details-header-text {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    text-align: center;
    margin-bottom: 15px;
    color: #858796;
}
/**************************** END OF UTDP DETAILS STYLING *********************/


/************************ UTDP DESCRIPTION STYLING ****************************/
.utdp-description-container {
    width: inherit;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-description-section {
    width: 100%;
}

.utdp-description-text span {
  background: transparent !important;
  color: white !important;
  font-size: 16px !important;
}

.utdp-description-header {
    font-weight: bold;
    font-size: 14px;
}

.utdp-description-text {
    font-size: 16px;
    color: #858796;
}

.utdp-description-container#video {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************** END OF UTDP DESCRIPTION STYLING **********************/


/**************************** UTDP TEAM STYLING *******************************/
.utdp-team-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-team-section {
    width: inherit;
}

.utdp-individual-team-member {
    border-bottom: 1pt solid #1588ee;
    width: inherit;
    font-size: 14px;
    padding-left: 40px;
}

.utdp-team-member-name {
    font-weight: bold;
    color: #858796;
}

.utdp-team-member-name:hover {
    color: white !important;
}
/*************************** END OF UTDP TEAM STYLING *************************/


/**************************** UTDP SOCIAL MEDIA STYLING ***********************/
.utdp-social-media-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-social-media-section {
    width: inherit;
    height: inherit;
}

.utdp-social-media-icons-row {
}

.utdp-social-media-icons-column {
}

.utdp-social-media-icons-column {
    display: flex;
    justify-content: center;
}

.utdp-social-icon {
    padding-right: 40px;
    font-size: 36px;
    transition: color 0.12s;
    color: #858796;
}

.utdp-social-icon:hover {
  color: #858796;
}
/********************* END OF UTDP SOCIAL MEDIA STYLING ***********************/


/************************* STO DETAILS STYLING ********************************/
.utdp-sto-details-container {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #858796;
    width: inherit;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-sto-details-section {
    width: 100%;
}

.utdp-sto-details-top-row {
    font-size: 13px;
}

.utdp-sto-details-row {
    border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
}

.utdp-sto-details-top-column {
}

.utdp-sto-details-column {
    width: 100%;
    padding: 0;
}

.utdp-sto-details-column a {
  color: rgba(255, 255, 255, 0.8);
  justify-content: flex-end;
  display: flex;
  text-decoration: underline;
}

.utdp-sto-details-column a:hover {
  color: #858796;
}

.utdp-title {
    font-weight: bold;
    margin-bottom: 3px;
    color: #858796;
}

.utdp-sto-details-content {
    text-align: right;
    color: rgba(255, 255, 255, 0.8);
}

.utdp-sto-details-document-row {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 10px;
}

/**************************** UTDP NEWS STYLING *******************************/
.utdp-news-header-text {
    text-align: center;
    color: #858796;
}

.utdp-news-section {
    width: inherit;
    margin-bottom: 20px;
    margin-top: 16px;
    margin-left: 0px;
    padding: 20px;
    padding-top: 0;
}

.utdp-individual-news-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-card-header-row {
    margin-bottom: 10px;
}

.utdp-news-card-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.utdp-news-card-title {
    font-size: 14px;
    padding: 0px 15px;
    margin-bottom: 15px;
}

.utdp-news-card-title a {
  color: #858796;
}

.utdp-news-card-title a:hover {

}

.utdp-read-more-row {
    /* margin-left: 5px; */
    width: 100%;
    margin-bottom: 10px;
    text-align: right;
}

.utdp-no-news {
    margin-top: 100px;
    /* font-weight: bold; */
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
}

.utdp-nav-bar {
  color: #253165;
  background: #B3CBE3;
  justify-content: space-around;
  padding: 32px 0;
  align-items: center;
}

.utdp-nav-bar p {
  margin: 0;
  border-bottom: 3px solid transparent;
}

.utdp-nav-item {
  font-size: 20px;
  justify-content: center;
  display: flex;
}

.utdp-nav-item#current-position p {
  border-bottom: 3px solid #253165;
}

.utdp-nav-item p:hover {
  cursor: pointer;
  border-bottom: 3px solid #253165;
}

.utdp-profile-info {
  color: #858796;
}

.utdp-news-row {
  justify-content: center;
}

.utdp-individual-section {
  /* margin-bottom: 20px; */
  padding: 32px 48px 0 32px;
  width: 100%;
}

.utdp-individual-section h3 {
  color: #253165;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.utdp-fields-row {
  justify-content: space-between;
}

.utdp-individual-field {
  border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
  margin-bottom: 16px;
  height: max-content;
  width: 100%;
  padding-bottom: 8px;
}

.utdp-individual-field#hidden {
  display: none;
}

.utdp-individual-field-label {
  margin: 0;
  color: #253165;
  width: 200px !important;
  height: 100%;
}

.utdp-individual-field-text-content {
  margin: 0;
}

.utdp-profile-sections-container {
  background: white;
  padding-top: 32px;
  color: #253165;
  width: 250px;
}

.utdp-profile-sections-header {
  justify-content: center;
  padding-bottom: 8px;
  font-size: 20px;
}

.utdp-individual-section-for-list {
  width: 100%;
  text-transform: uppercase;
  padding: 20px;
}

.utdp-individual-section-for-list:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.utdp-individual-section-for-list:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.utdp-coming-soon {
  color: #858796;
  justify-content: center;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-container {
  max-width: 1000px;
  padding: 32px;
}

.utdp-individual-article {
  margin-bottom: 16px;
  /* padding: 20px; */
  width: 100%;
  color: #858796;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  /* max-width: 1000px; */
  padding-bottom: 8px;
}

.utdp-individual-article a {
  color: #253165 !important;
}

.utdp-individual-article a:hover {
}

.utdp-article-info {
  color: #858796;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.utdp-article-title {
  margin: 0;
  font-size: 22px;
  color: #253165;
}

.utdp-article-summary {
  color: black;
  margin-bottom: 8px;
}

.utdp-article-link {
  color: #253165 !important;
  font-size: 14px;
}

.utdp-field-image {
  max-width: 40px;
  max-height: 40px;
}

.utdp-field-image-tooltip {
  width: 100%;
  max-height: 400px;
}

.user-content-container {
  min-height: 100vh;
  position: relative;
  color: #858796;
  /* padding: 36px; */
  justify-content: center;
  display: flex;
  padding: 0;
}

.asset-profile-container {
  /* width: 1000px; */
  /* padding: 0; */
  padding: 0;
}

.utdp-header-section {
  background: #253165;
  font-size: 13px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.button-navlink {
  padding: 0;
  margin-left: 8px;
}

.button-navlink button {
  padding: 0 8px;
}

.utdp-header-name {
  font-size: 48px;
  align-items: center;
}

.utdp-header-info-row {
  margin-top: 16px;
}

.utdp-header-info {
  font-size: 14px;
}

.utdp-header-info .row {
  align-items: center;
}

.utdp-header-info-icon {
  margin-right: 8px;
  height: 100%;
}

.profile-scrollspy {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.individual-section-for-scrollspy {
  width: 100%;
  padding: 16px;
  color: #253165;
  background: white;
}

.individual-section-for-scrollspy:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.05)
}

.individual-section-for-scrollspy:not(:last-child) {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.utdp-see-more-row {
  justify-content: center;
  text-decoration: underline;
  font-size: 20px;
  padding: 32px;
}

.utdp-image-row {
  width: 100%;
  justify-content: center;
  margin-left: 16px;
  margin-bottom: 8px;
}

.utdp-image {
  max-width: 300px;
}

.utdp-image:hover {
  cursor: pointer;
}

.utdp-video {
  border: none;
  height: 240px;
  width: 360px;
}

.utdp-anchor {
}

.utdp-section-tile-row {
  justify-content: center;
}

.utdp-individual-tile-field {
  width: 200px;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 3px;
}

.utdp-individual-tile-field::-webkit-scrollbar {
  width: 4px;
}

.utdp-individual-tile-field::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 25px;
}

.utdp-individual-tile-field .utdp-image-row {
  justify-content: center !important;
  margin: 0 !important;
}

.utdp-individual-tile-field .utdp-image {
  width: 100%;
}

.utdp-individual-tile-field .utdp-social-icon-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-form-link-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-social-icon-link {
  font-size: 40px;
}

.utdp-individual-tile-field .utdp-doc-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-video {
  height: 100%;
  width: 100%;
}

.utdp-individual-tile-field .utdp-form-link-row {
  /* justify-content: center; */
}

.utdp-offering-wrapper{
  margin-top:10px;
  margin-left: 10%;
  margin-right:10%;
  border-style:solid;
  border-color:#253165;
  border-radius:5px;
}

.utdp-offering-row {
  margin-bottom: 16px;
  /* width: 1000px; */
}

.utdp-offering-header-row {
  background: #B3CBE3;
  padding: 8px;
  height: 45px;
  align-items: center;
  color: #212A5C;
}

.utdp-offering-table-row {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 8px;
  /* background: white; */
}

.utdp-table-title {
  /* width: 200px; */
  display: flex;
  align-items: center;
}

.utdp-offering-table {
  font-size: 14px;
  border: none;
  color: #858796;
  margin: 0;
  /* table-layout: fixed; */
}

.utdp-offering-table tr {
  border: none;
}

.utdp-offering-table th {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  justify-content: flex-end !important;
  width: 200px;
  text-align: center;
  font-size:18px;
}

.utdp-offering-table td {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  color: #212A5C;
  font-size: 18px;
  /* width: 200px; */
  text-align: center;
}

.utdp-table-profile-name {
  color: #212A5C;
  font-size: 28px;
}

.utdp-user-image-icon {
  font-size: 20px;
  margin-right: 8px;
}

.utdp-user-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 8px;
}

.utdp-header-offering-row {
  margin-top: 16px;
  justify-content: space-between;
}

.utdp-header-offering-row .row {
  align-items: center;
}

.utdp-header-offering-icon {
  font-size: 64px;
  margin-right: 8px;
}

.utdp-header-offering-label {
   font-size: 20px; 
  margin: 0;
}

.utdp-header-data {
  font-size: 32px;
  margin: 0;
}

.utdp-header-info-row {
  margin: 0;
  align-items: center;
  justify-content: space-between;
}

.utdp-header-link {
  color: #1588ee !important;
}
